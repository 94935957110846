import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default props => {
  console.log(props)
  const {
    mdx: {
      body,
      frontmatter: { title },
    },
  } = props.data

  return (
    <Layout>
      <article>
        <header>
          <h1>{title}</h1>
        </header>
      </article>

      <MDXRenderer children={body} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      body
    }
  }
`
